.hotelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
source.videomodal {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.hotelWrapper {
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.bookNow {
  width: 25%;
  position: absolute;
  top: 10px;
  right: 0;
  border: none;
  padding: 10px 20px;
  background-color: #0071c2;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.hotelTitle {
  font-size: 24px;
}

.hotelAddress {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.hotelDistance {
  color: #0071c2;
  font-weight: 500;
}

.hotelPriceHighlight {
  color: #008009;
  font-weight: 500;
}

.hotelImages {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 7px;
  margin-top: 3%;
}
.firstImageRow {
  width: 49%;
  margin-right: 1px;
}
.otherImagesRow {
  width: 24%;
}
ul.amenities-list2 li{
  line-height: 33px;
  font-size: 18px;
  margin-right: 35px;
  margin-left: 37px;
}
.otherImagesRows {
  width: 24%;
}
.hotelImgWrapper {
  margin-bottom: 7px;
  
}

.hotelImg {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  /* border-radius: 10px; */
}

.hotelDetails {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 0px;
}

.hotelDetailsTexts {
  flex: 3;
}

.hotelDesc {
  font-size: 14px;
  margin-top: 20px;
}

/* .hotelDetailsPrice {
  flex: 1;
  background-color: #ebf3ff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
} */

.hotelDetailsPrice > h1 {
  font-size: 18px;
  color: #555;
}
.hotelDetailsPrice > span {
  font-size: 14px;
}
.hotelDetailsPrice > h2 {
  font-weight: 300;
}
.hotelDetailsPrice > button {
  border: none;
  background-color: #000000;
  color: white;
  font-weight: 500;
  border: none;
  padding: 12px 30px;
  cursor: pointer;
  border-radius: 0px;
  text-decoration: none;
  font-size: 16px;
  transition: 1s ease all;
  line-height: 29px;
}
h6.hotelImagesdata{
  text-align: right;
  margin-top: 20px;
}

.slider {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0 0 0 / 75%);
  z-index: 99999;
  display: flex;
  align-items: center;

}


.sliderWrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}
/* 
.sliderImg{
  width: 80%;
  height: 80vh;
} */

section.single-prop-img img.sliderImg {
  width: 600px;
  height: 500px;
  border-radius: 10px;
}
.close{
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.arrow{
  margin: 20px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}



@media (max-width: 374px) and (min-width: 320px) {
  .tabs {
      flex-wrap: wrap; 
      justify-content: center;
  }
  .additionalfacilities{
      flex-wrap: wrap;

  }
  div#aditionalfacilites-01 {
      width: 100%;
  }
  .additionalfacilitydiv2{
      flex-wrap: wrap;
  }
 
}

@media (max-width: 424px) and (min-width: 375px) {
  .tabs {
      flex-wrap: wrap; 
      justify-content: center;
  }
  .additionalfacilities{
      flex-wrap: wrap;

  }
  div#aditionalfacilites-01 {
      width: 100%;
  }
  .additionalfacilitydiv2{
      flex-wrap: wrap;
  }

}

@media (max-width: 459px) and (min-width: 425px) {

 button.tab-btn1.active {
  padding: 15px 25px;
  border-radius: 33px;
  background: #F47943;
  color: white;
  border: none;
  font-size: 20px;
  width: 40%;
}
button.tab-btn1 {
padding: 15px 25px;
border-radius: 33px;
background: white;
font-size: 20px;
border-color: #606C7266;
/* width: 60%; */
} 
}
@media (max-width: 675px) and (min-width: 425px) {
  .tabs {
      flex-wrap: wrap; 
      justify-content: center !important;
  }
  .additionalfacilities{
      flex-wrap: wrap;

  }
  button.tab-btn1.active {
    padding: 15px 25px;
    border-radius: 33px;
    background: #F47943;
    color: white;
    border: none;
    font-size: 20px;
    width: 50%;
}
button.tab-btn1 {
  padding: 15px 25px;
  border-radius: 33px;
  background: white;
  font-size: 20px;
  border-color: #606C7266;
  /* width: 60%; */
}
  div#aditionalfacilites-01 {
      width: 100%;
  }
  .additionalfacilitydiv2{
      flex-wrap: wrap;
  }

}

@media (max-width: 767px) and (min-width: 676px) {
}

@media (max-width: 991px) and (min-width: 768px) {}

@media (max-width: 1023px) and (min-width: 992px) {}

@media (max-width: 1199px) and (min-width: 1024px) {}

@media (max-width: 1300px) and (min-width: 1200px) {}

@media (max-width: 1440px) and (min-width: 1301px) {

section.inner-form .container {
  max-width: 1300px !important;
}
}