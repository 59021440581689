.slide-property {
    width: 100%;
    height: 580px;
   gap: 0px;
}





@media (max-width: 767px) and (min-width: 320px) {
    .slide-property {
        width: 100%;
        height: auto;
        gap: 0px;
    }

}
@media (max-width: 991px) and (min-width: 768px) {
section.inner-form {
    margin-top: 50px;
    bottom: 0px;
}
}
@media (max-width: 1199px) and (min-width: 992px) {
.last-btn button {
 
    height: 160px;
    
}
div#main-inner-form {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    align-items: center;
    background: #fff;
    border-radius: 10px;
}
}