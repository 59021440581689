.newone h1{
  font-size: 80px;
}
a.girars1 {
  display: none;
}
.togglediv img.girlcars1 {
  display: none;
}
.mobile-head {
  display: none;
}
img.blacklogo20 {
  margin: 0 30px;
}
.togglediv img.girlcars1 {
  margin: 0 30px;
}
.imagespart{
  padding-top: 20px;
}

.navicon{
  padding: 15px 5px;
  /* background-color: blue; */
}
.home a {
  text-transform: capitalize;
  font-size: 18px;
}
 
.home {
  margin-top: 20px;
  float: right;
  /* display: flex;
  gap:20px;
  justify-content: end;
  color: white !important;
  text-decoration: none;
  padding: 15px 5px;
  align-items: center; */
  /* text-align: end; */
}
.home a {

  /* color: white !important; */
text-decoration: none;

}

.navbarpart{
  padding-top: 20px;
}
.togglediv {
 /* width: 1400px !important; */
  /* position: fixed; */
  position: absolute;
  /* height: inherit; */
  top: 0;
  left: 0;
  z-index: 90;
  background-color: white;
  animation: slideDown .7s ease-in-out;
  border-radius: 0px 10px 10px 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
@keyframes slideDown {
  0% {
    transform: translateY(-100%); /* Start above the viewport */
    opacity: 0; /* Fully transparent */
  }
  100% {
    transform: translateY(0); /* End at its normal position */
    opacity: 1; /* Fully visible */
  }
}
.images2 {
  margin-top: 20px;
}
.icon{
 color:orangered;
 text-align: end;
  padding-top: 30px
}


.chatmsg-img{
    width: 100% !important;
    height: 210px !important;
    border-radius: 15px;
    margin-bottom: 20px;
}
ul.homebar li {
  margin-top: 0.5rem;
}
.btn-style {
  display: inline-block;
  background-color: #ffffff; 
  color: rgb(0, 0, 0);
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 5px; 
  transition: background-color 0.3s ease;
}
.navbar-header {
  z-index: 99999;
}
.btn-style:hover {
  color: white;
  background-color: #00a6cf; 
  transition: background-color 0.3s ease;
}

section.navbar4 {
  position: absolute;
  z-index: 11111;
  width: 100%;
  background: linear-gradient(to top, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 78%) 91%);
}
/* .navbar-header {
  margin-top: 20px;
} */
.main-icon1{
  width: 64px;
  text-align: center;
  padding: 18px;
  background-color: black;
 color: white;
  border-radius: 50%;
  transition: 0.7s;
}
/* .searchicon1{
  width: 47px;
  text-align: center;
  padding: 10px;
   border: 1px solid white;
 color: white;
  border-radius: 50%;
} */

section.new-menu11 a.headerBtn3.dropdown-item {
  padding: 0 !important;
}

.dropdown-menu.show{
  color: white !important;
  background: black;
  text-align: center;
  padding: 15px !important;
}

.section.new-menu11 .dropdown-item :hover{
  background-color: black !important;
}
a.headerBtn3.dropdown-item:hover {
  background-color: black !important;
  color: white !important;
}

@media (max-width: 767px) and (min-width: 320px){
  a.btn-style {
    padding: 10px 20px;
}
.home {
  display: none;
}

.logo-imgg-1{
  width: 100%;
  text-align: center;
}
.navbar-header {
  margin: 10px;
}
img.logo-mobile {
  width: 150px;
  /* margin-top: -10px; */
}
.home a {
  text-transform: capitalize;
  font-size: 16px;
}
.main-icon1 {
  width: 45px;
  padding: 10px;
}
.btn-style {
  display: inline-block;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  text-decoration: none;
  padding: 10px 0px;
  border-radius: 30px;
  transition: background-color 0.3s ease;
}
section.inner-form {
  margin-top: 130px;
}
img.hotelImg {
  height: 250px;
}
.review-swiper-slider {
 
  margin: 0 auto;
  display: block;
  text-align: center;
}
.additionalfacilitydiv3 {
  display: block;
  margin: auto;
}
.additionalfacilitydiv5 {
  text-align: center;
}
.additionalfacilitydiv10 {
  display: block;
  margin: auto;
}
.tabs {
  justify-content: center;
}
section.new.footer3 {
 
  text-align: center;
}
.new-list {
  width: 100%;
 flex-wrap: wrap;
}
.social-icons {
  text-align: center;
  margin: auto;
  display: block;
}
.new-list{
display: block;

}
}

.headerBtn2:hover{
  color: #F47943 !important;
}
