.additionalfacilities {
    display: flex;
    gap: 22px;
    justify-content: left;
}


div#aditionalfacilites-01 {
    background: linear-gradient(180deg, #EFFBFF 0%, #FFE4D9 139.72%);
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

}

.additionalfacilitydiv2 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.additionalfacilitydiv3 {
    width: 50%;
    height: 100%;
    margin-bottom: 18px;
}

.additionalfacilitydiv5 {
    padding: 0px 20px;
    text-align: left;
}

.additionalfacilitydiv6>strong {
    font-size: 22px;
}

.additionalfacilitydiv5>p {
    font-size: 18px;
}

.additionalfacilitydiv10 {
    width: 50%;
    height: 100%;
    margin-bottom: 24px;
}

button.tab-btn1.active {

    padding: 15px 25px;
    border-radius: 33px;
    background: #F47943;
    color: white;
    border: none;
    font-size: 20px;
}

button.tab-btn1 {

    padding: 15px 25px;
    border-radius: 33px;
    background: white;
    font-size: 20px;
    border-color: #606C7266;
}

.tabs {
    gap: 20px;
    display: flex;
    justify-content: space-between;
}

.propertiesfacilitiesdiv {
    margin-bottom: 26px;
    margin-top: 30px;
    padding-top: 30px;
}

.amenity-item1 {
    list-style: disc;
}

.amenity-items1 {
    list-style: disc;
}

.unorderlistrow {
    padding-left: 20px !important;
}