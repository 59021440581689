.rInput{
    height: 30px;
    padding: 10px;
    /* text-transform: capitalize; */
    /* text-decoration: none; */
  }
  /* input[type="email"] {
    width: 100% !important;
    height: 27px !important;
    text-align: left !important;
    font-size: 14px !important;
} */
  .rContainer {
    background-color: white;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .register {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rButton {
    border: none;
    padding: 10px 20px;
    background-color: #0071c2;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    width: 112%;
    height: 45px;
    font-size: 15px;
}
